import React from 'react';
import { IoDownload } from 'react-icons/io5';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '400px'
}
function HomeHeader() {
    const sliderImg = [
        {
            img: require("../img/sliders/img.jpeg"),
            caption: "Caption for img.jpeg"
        },
        {
            img: require("../img/sliders/img2.jpeg"),
            caption: "Caption for img2.jpeg"
        },
        {
            img: require("../img/sliders/img3.jpeg"),
            caption: "Caption for img3.jpeg"
        },
        {
            img: require("../img/sliders/img4.jpeg"),
            caption: "Caption for img4.jpeg"
        },
        {
            img: require("../img/sliders/img5.jpeg"),
            caption: "Caption for img5.jpeg"
        },
        {
            img: require("../img/sliders/img6.jpeg"),
            caption: "Caption for img6.jpeg"
        },
        {
            img: require("../img/sliders/img7.jpeg"),
            caption: "Caption for img7.jpeg"
        },
        {
            img: require("../img/sliders/img8.jpeg"),
            caption: "Caption for img8.jpeg"
        },
        {
            img: require("../img/sliders/img9.jpeg"),
            caption: "Caption for img9.jpeg"
        }
    ];
    
    React.useEffect(() => {

        const handleScroll = () => {
            if (window.pageYOffset > 100) {
                document.querySelector("#header").classList.add("duration-500");
            } else {
                document.querySelector("#header").classList.remove("duration-500");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div id='header' className="flex justify-center items-center p-5 transition ">
            <div className="w-full md:w-[90%]">
                <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-10 p-5">
                    <div className="flex flex-col p-2 gap-5">
                        <h1 style={{ lineHeight: '3.5rem' }} className="text-3xl  tracking-wide  md:text-4xl font-bold text-center md:text-left">
                            Bienvenue sur notre plateforme d’inscription de <span className="text-teal-500 text-5xl underline">ESTLC</span> !
                        </h1>
                        <p className="text-center md:text-left">
                            Nous vous recommandons de télécharger l’arrêté de lancement du concours qui pourra vous être utile plus tard. Faites-le en cliquant sur le bouton suivant en fonction de votre préférence.
                        </p>
                        <div className="flex flex-col md:flex-row items-center justify-between gap-3">
                            <button className="px-4 py-3 rounded-lg bg-teal-500 flex items-center gap-3 text-white">
                                Version Anglaise <IoDownload size={25} />
                            </button>
                            <button className="px-4 py-3 rounded-lg bg-teal-500 flex items-center gap-3 text-white">
                                Version Française <IoDownload size={25} />
                            </button>
                        </div>
                        <p className="text-sm text-center md:text-left">
                            Veuillez remplir toutes les informations nécessaires pour le concours d’entrée à notre école. Si vous avez des requêtes, veuillez les indiquer ici pour que nous puissions y répondre au mieux.
                        </p>
                    </div>
                    <div className="w-full md:w-auto">
                        {/* <img src={require('../img/student.png')} alt="Student" className="w-full md:w-auto" /> */}
                        <Slide>
                            {sliderImg.map((slideImage, index) => (
                                <div key={index}>
                                    <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.img})` }}className='relative'>
                                        <span className='absolute bottom-0 w-full bg-teal-200/50 p-3 text-xl font-bold'>{slideImage?.caption}</span>
                                    </div>
                                </div>
                            ))}
                        </Slide>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeHeader;
