import React, { useState } from 'react';
import { LuEye, LuEyeOff, LuFileUp } from 'react-icons/lu';
import { fieldSet, notEmpty } from '../../utils/validation';
import { toast } from 'react-toastify';
import { createCompte } from '../../api/routes/compte';
import { useDispatch } from 'react-redux';
import { push_candidate_info } from '../../app/modules/candidate';
import { useNavigate } from 'react-router-dom';
//import PDFPreview from '../PDFPreview';

function PaymentInfo({onClose,isLoad,setLoadingState}) {
    const [imageObject, setImageObject] = useState("");
    const [formData, setFormData] = useState({});
    const [showPassword,setPasswordShow] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    function onChange(e) {
        setFormData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }
    React.useEffect(() => {
        fieldSet('#form_pay', setFormData, {})
        return () => {
            fieldSet('#form_pay', setFormData, {})
        };
    }, [])

    function onSubmit(e) {
        try {
            e.preventDefault();
            if (notEmpty(formData)) {
                // Handle form submission
                const {ca_pwd,ca_confirm_pwd} = formData
                if (ca_confirm_pwd !==ca_pwd) {
                    return toast.error("Les mots de passe ne sont pas identiques")
                }
                setLoadingState(true);
                const {ca_recu,...data} = formData;
                createCompte(data,ca_recu).then(async (response)=>{
                    if (!response.ok) {
                        setLoadingState(false);
                        onClose()
                        return toast.error("Une erreur interne, vos données n'ont pas été envoyé")
                    }
                    if (response.status ===200) {
                       // console.log(await response)
                        setLoadingState(false);
                        onClose()
                        fieldSet('#form_pay', setFormData, {})
                        dispatch(push_candidate_info(data))
                        toast.success("Les informations ont été soumise avec succès")
                        return navigate('/candidate')
                    }
                })
            } else {
                // Handle error
                toast.error("Veuillez remplir tous les champs...")
            }
        } catch (error) {
            console.log(error);

        }
    }
    function showPwd(){
        setPasswordShow(!showPassword);
    }
    function onFileSelected(e) {
        try {
            const file = e.target.files[0];
            const src = URL.createObjectURL(file);
            setFormData(prevData => ({
                ...prevData,
                [e.target.name]: e.target.files[0]
            }));
            setImageObject(src);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='flex flex-col gap-5 lg:flex-row' id='form_pay'>
            <div className='flex-1'>
                <form onSubmit={onSubmit}>
                    <div className='flex flex-col gap-3 mb-3'>
                        <label htmlFor="ca_nom">Nom<sup className='text-red-600'>*</sup></label>
                        <input type="text" id='ca_nom' name='ca_nom' placeholder='Exp MAHOP MAHOP' className='p-2 border border-teal-600 rounded-md outline-none focus:ring focus:ring-teal-600/50 indent-1' onChange={onChange} />
                    </div>
                    <div className='flex flex-col gap-3 mb-3'>
                        <label htmlFor="ca_prenom">Prenom<sup className='text-red-600'>*</sup></label>
                        <input type="text" id='ca_prenom' name='ca_prenom' placeholder='Exp BORIS JUNIOR' className='p-2 border border-teal-600 rounded-md outline-none focus:ring focus:ring-teal-600/50 indent-1' onChange={onChange} />
                    </div>
                    <div className='flex flex-col gap-3 mb-3'>
                        <label htmlFor="ca_email">Email<sup className='text-red-600'>*</sup></label>
                        <input type="email" id='ca_email' name='ca_email' placeholder='Exp cicsoestlc@gmail.com' className='p-2 border border-teal-600 rounded-md outline-none focus:ring focus:ring-teal-600/50 indent-1' onChange={onChange} />
                    </div>
                    <div className='flex flex-col gap-3 mb-3'>
                        <label htmlFor="ca_recu">N° de reçu <sup className='text-red-600'>*</sup></label>
                        <input type="text" id='ca_recu' name='ca_num_recu' placeholder='BGQRTU1256TYVDKJ-MMBJ09' className='p-2 border border-teal-600 rounded-md outline-none focus:ring focus:ring-teal-600/50 indent-1' onChange={onChange} />
                    </div>
                    <div className='flex flex-col gap-3 mb-3'>
                        <label htmlFor="password">Votre mot de passe <sup className='text-red-600'>*</sup></label>
                        <div className="relative w-full">
                        <input type={!showPassword?"password":"text"} id='password' name='ca_pwd' placeholder='' className='p-2 border border-teal-600 rounded-md outline-none focus:ring focus:ring-teal-600/50 indent-1 w-full' onChange={onChange} />
                         <div className='absolute top-2 flex text-teal-500 cursor-pointer right-2' onClick={showPwd}>
                         {!showPassword?<LuEye size={25}/>:<LuEyeOff size={25}/>}
                         </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3 mb-3'>
                        <label htmlFor="confirm_password">Confirmer votre mot de passe <sup className='text-red-600'>*</sup></label>
                        <div className="relative w-full">
                        <input type={!showPassword?"password":"text"} id='confirm_password' name='ca_confirm_pwd' placeholder='' className='p-2 border border-teal-600 rounded-md outline-none focus:ring focus:ring-teal-600/50 indent-1 w-full' onChange={onChange} />
                         <div className='absolute top-2 flex text-teal-500 cursor-pointer right-2' onClick={showPwd}>
                         {!showPassword?<LuEye size={25}/>:<LuEyeOff size={25}/>}
                         </div>
                        </div>
                    </div>
                    <div className='relative flex flex-col gap-3 mb-3'>
                        <label htmlFor="recu_image">Image du reçu <sup className='text-red-600'>*</sup></label>
                        <input type="file" id='recu_image' name='ca_recu' onChange={onFileSelected} accept='.pdf' className='z-10 p-2 border opacity-0 appearance-none file:appearance-none file:bg-transparent file:border' />
                        <span className='absolute text-5xl text-teal-500 top-9 left-3' >
                            <LuFileUp />
                        </span>
                       <p className='text-red-500'>
                         Reçu scanner en pdf, dépassant pas 2 Mo
                       </p>
                    </div>
                    {/* <div className='flex flex-col gap-3 mb-3'>
                        <label htmlFor="email">Email <sup className='text-red-600'>*</sup></label>
                        <input type="email" id='email' name='ca_email' placeholder='' className='p-2 border border-teal-600 rounded-md outline-none focus:ring focus:ring-teal-600/50 indent-1' onChange={onChange} />
                    </div> */}
                    <div className="w-full flex items-center justify-center">
                        <button type='submit' className='w-1/2 p-2 text-white bg-teal-600 rounded-md'>Soumettre</button>
                    </div>
                </form>
            </div>
            <div id='preview' className='flex-1 '>
                {/* <div className='max-w-full overflow-auto'>
                {imageObject && <PDFPreview pdfUrl={imageObject}/>}
                </div> */}
            </div>

        </div>
    );
}

export default PaymentInfo;
