import { GET_CANDIDATE_API, GET_DIPLOME_API, STORE_CANDIDATE_API } from "..";

export async function createCandidate(body) {
    const { url, ...meta } = STORE_CANDIDATE_API;
    return await fetch(url, {...meta,body:JSON.stringify(body),credentials:'include'})
}

export async function getCandidate() {
    const { url, ...meta } = GET_CANDIDATE_API;
    return await fetch(url, meta)
}

export async function getDiplome(params) {
    const { url, ...meta } = GET_DIPLOME_API;
    return await fetch(url, {...meta, body:JSON.stringify(params),credentials:'include'})
}