import React, { useState } from 'react';
import { loginAPI } from '../api/routes/auth';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/stepModal/Loading';

function LoginPage() {
  const [formData, setFormData] = useState({
    login: '',
    password: '',
  });
  const [isLoad,setLoadingState] = useState(false)
  const navigate = useNavigate()
  function onChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  function onSubmit(e) {
    e.preventDefault();
    // Handle form submission
    setLoadingState(true)
    loginAPI(formData).then(async(res)=>{
      if(res.status===200){
        setLoadingState(false)
        const data = await res.json()
        const {access_token,token_type,user} = data
        sessionStorage.setItem('token',access_token)
        sessionStorage.setItem('type_token',token_type)
        sessionStorage.setItem('user',JSON.stringify(user))
        window.location.href ='/candidate'
      }
    })
  }

  return (
    <div className='w-full h-[90vh] flex justify-center items-center'>
      <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-md flex justify-between items-center">
        <div className='w-1/2'>
        <h2 className='text-5xl font-bold text-teal-500'>
          Veuillez vous connectez
        </h2>
          <form onSubmit={onSubmit} className='space-y-6'>
            <div className='flex flex-col gap-3 mb-3'>
              <label htmlFor="ca_prenom">Login (<i>N° reçu de paiement </i>)<sup className='text-red-600'>*</sup></label>
              <input
                type="text"
                id='ca_prenom'
                name='login'
                placeholder='Exp: 89632168'
                className='p-2 border border-teal-600 rounded-md outline-none focus:ring focus:ring-teal-600/50 indent-1'
                onChange={onChange}
                value={formData.login}
                required
              />
            </div>
            <div className='flex flex-col gap-3 mb-3'>
              <label htmlFor="password">Mot de passe<sup className='text-red-600'>*</sup></label>
              <input
                type="password"
                id='password'
                name='password'
                placeholder='********'
                className='p-2 border border-teal-600 rounded-md outline-none focus:ring focus:ring-teal-600/50 indent-1'
                onChange={onChange}
                value={formData.password}
                required
              />
            </div>
            <button type="submit" className='w-full py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700'>
              Connectez-vous
            </button>
          </form>
        </div>
        <div className='w-1/2 flex justify-center items-center'>
          <img className='w-3/4' src={require("../img/student2.png")} alt="Student" />
        </div>
      </div>
      {isLoad&&<Loading/>}
    </div>
  );
}

export default LoginPage;
