import React from 'react'
import Svg500 from '../../img/undraw_feeling_blue_-4-b7q.svg'
function Page500() {
  return (
    <div className='flex items-center flex-col justify-center'>
    <div>
       
        <img src={Svg500} className='w-3/4' alt="" />
    </div>
</div>
  )
}

export default Page500