import React, { useState } from 'react';
import { LuArrowLeft } from 'react-icons/lu';
import { useDispatch, useSelector} from 'react-redux';
import { toast } from 'react-toastify';
import { initStep, prevStep } from '../../app/modules/stepper';
import CustomCheckbox from '../CustomCheckbox';
//import { push_candidate_info } from '../../app/modules/candidate';
import { createCandidate } from '../../api/routes/candidate';
import { useNavigate } from 'react-router-dom';
function Confirmation({ setLoadingState }) {
    const [formData, setFormData] = useState({
        birth_certificate: false,
        diploma: false,
        school_attendance: false,
        medical_certificate: false,
        bank_receipt: false,
        envelope: false
    });
    const {finish,...userData} = useSelector((state)=>state.candidate.candidate_state)
    const {id} = useSelector((state)=>state.candidate.session)??JSON.parse(sessionStorage.getItem("session"))

    function onChange(e) {
        const { name, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: checked
        }));
    }

    const dispatch = useDispatch();
    const navigate = useNavigate()
    function onSubmit(e) {
        try {
            e.preventDefault();
            const allChecked = Object.values(formData).every(value => value);
            if (allChecked) {
                // Form processing logic
                setLoadingState(true);
                //dispatch(push_candidate_info(formData));
                
                setTimeout(() => {
                    createCandidate({...userData,id}).then(async function(res){
                        if (res.status ===201) {
                            setLoadingState(false);
                            dispatch(initStep())
                            navigate('/affiche-data')
                        }else{
                            setLoadingState(false);
                            console.log(await res.json())
                            toast.error("Une erreur s'est produite")
                        }
                    })
                    
                }, 1000);
            } else {
                // Handle empty form error
                toast.error("Veuillez cocher tous les champs requis...");
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='mt-12'>
            <form id='form_docs' onSubmit={onSubmit}>
                <div className="grid items-center grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
                    <div className='flex items-center gap-3 mb-3'>
                        <CustomCheckbox checked={formData.birth_certificate} onChange={onChange} id='birth_certificate' name="birth_certificate" />
                        <label htmlFor="birth_certificate">Une photocopie certifiée de l'acte de naissance datant de moins de trois (03) mois</label>
                    </div>
                    <div className='flex items-center gap-3 mb-3'>
                        <CustomCheckbox checked={formData.diploma} id='diploma' name="diploma" onChange={onChange} />
                        <label htmlFor="diploma">Une photocopie certifiée conforme du diplôme/attestation de réussite du Baccalauréat ou du GCE A/L ou du diplôme équivalent</label>
                    </div>
                    <div className='flex items-center gap-3 mb-3'>
                        <CustomCheckbox checked={formData.school_attendance} id='school_attendance' name="school_attendance" onChange={onChange} />
                        <label htmlFor="school_attendance">Un certificat de scolarité de la classe de terminale/upper sixth</label>
                    </div>
                    <div className='flex items-center gap-3 mb-3'>
                        <CustomCheckbox checked={formData.medical_certificate} id='medical_certificate' name="medical_certificate" onChange={onChange} />
                        <label htmlFor="medical_certificate">Un certificat médical délivré par un médecin fonctionnaire, datant de moins de trois (03) mois et certifiant que le candidat est apte à poursuivre des études supérieures</label>
                    </div>
                    <div className='flex items-center gap-3 mb-3'>
                        <CustomCheckbox checked={formData.bank_receipt} id='bank_receipt' name="bank_receipt" onChange={onChange} />
                        <label htmlFor="bank_receipt">Un reçu de versement bancaire d'un montant de vingt mille (20 000) francs CFA représentant les droits d'inscription au concours de l'ESTLC de l'Université d'Ebolowa, à verser au compte SCB Cameroun, N° 10002.00059.90001487491.26 dans toutes les agences du Cameroun</label>
                    </div>
                    <div className='flex items-center gap-3 mb-3'>
                        <CustomCheckbox id='envelope' checked={formData.envelope} name="envelope" onChange={onChange} />
                        <label htmlFor="envelope">Une enveloppe A4 timbrée au tarif réglementaire et portant l'adresse exacte du candidat</label>
                    </div>
                </div>
                {/* <div className="flex items-center justify-between">
                    <button type='submit' className='flex items-center justify-center gap-2 p-2 text-white bg-teal-600 rounded-md'>
                        <LuArrowLeft /> Précédent
                    </button>
                    <button type='submit' className='flex items-center justify-center gap-2 p-2 text-white bg-teal-600 rounded-md'>
                        Enregistrer <LuArrowRight />
                    </button>
                </div> */}
                <div className="flex items-center justify-between">
                    <button type='button' onClick={() => dispatch(prevStep())} className='flex items-center justify-center gap-2 p-2 text-white bg-teal-600 rounded-md'>
                        <LuArrowLeft /> Précédent
                    </button>
                    <button type='submit' className='flex items-center justify-center gap-2 p-2 text-white bg-teal-600 rounded-md'>
                        S'inscrire Maintenant
                    </button>
                </div>

            </form>
        </div>
    );
}

export default Confirmation;
