import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import LoginPage from "./pages/Login";
import "react-toastify/ReactToastify.css"
import { ToastContainer } from "react-toastify";
import Candidate from "./pages/Candidate";
import { PersistGate } from 'redux-persist/integration/react';
import Loading from "./components/stepModal/Loading";
import { persistor } from "./app/store";
import PrivateRoute from "./components/PrivateRoute";
import AfficheDonnee from "./pages/AfficheDonnee";
import SiteExam from "./pages/SiteExam";
import ClientLayout from "./components/layouts/ClientLayout";
import BaseLayout from "./components/layouts/BaseLayout.jsx";
/* import { ThemeProvider } from "./components/context/ThemeContext.jsx";
import { SidebarProvider } from "./components/context/SidebarContext.jsx"; */
import Dashboard from "./components/screens/dashboard/DashboardScreen.jsx";
import Page500 from "./pages/errors/Page500.jsx";
import Page404 from "./pages/errors/404.jsx";
function App() {
  return (
    <PersistGate loading={<Loading />} persistor={persistor}>
      <BrowserRouter>
        {/* <Navbar /> */}
        <Routes >
          <Route path="/" element={<ClientLayout/>} >
            <Route path="" element={<Home />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="site-exam" errorElement={<Page500/>} element={<SiteExam />} />
            <Route path="candidate" element={<PrivateRoute />}>
              <Route path="" errorElement={<Page500/>} element={<Candidate />} />
            </Route>
            <Route path="affiche-data" element={<PrivateRoute />}>
              <Route path="" errorElement={<Page500/>} element={<AfficheDonnee />} />
            </Route>
            <Route path="*" element={<Page404/>}/>
          </Route>
          <Route path="/admin" element={<BaseLayout/>}  >
            <Route path="dashboard" errorElement={<Page500/>} element={<Dashboard />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="*" element={<Page404/>}/>
          </Route>
        </Routes>
        
        <ToastContainer />
      </BrowserRouter>
    </PersistGate>
  );
}

export default App;
