import React from 'react'
import Svg404 from '../../img/undraw_page_not_found_re_e9o6.svg'
function Page404() {
  return (
    <div className='flex items-center flex-col justify-center'>
        <div>
           
            <img src={Svg404} className='w-3/4' alt="" />
        </div>
    </div>
  )
}

export default Page404