import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { persistor } from '../app/store';
import { useDispatch } from 'react-redux';
import { logout } from '../app/modules/candidate';

function Navbar() {
  //const [isOpen, setIsOpen] = React.useState(false);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [hasToken, setToken] = useState('')
  const toggleMobileMenu = () => {
    //setIsMobileMenuOpen(!isMobileMenuOpen);
    
  };
  const dispatch = useDispatch()
  function logoutAction(){
    dispatch(logout())
    persistor.purge()
    window.location.reload()
  }
  React.useEffect(() => {
    const token = sessionStorage.getItem("token")
    setToken(token)
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        document.querySelector("nav").classList.add("fixed", "top-0", "transition", "duration-300", "w-full", "bg-white", "shadow-lg");
      } else {
        document.querySelector("nav").classList.remove("fixed", "top-0", "transition", "duration-300", "w-full", "bg-white", "shadow-lg");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <nav className='shadow flex justify-between items-center z-[500] px-8 py-5 '>
        <div>
          <Link to={''}>
            <img className='w-24 h-12' src={require("../img/logo.png")} alt="" />
          </Link>
        </div>
        <ul className='hidden md:flex items-center gap-8'>
          <li>
            <Link to={'/'}>Acceuil</Link>
          </li>
          <li>
            <Link to={'/site-exam'}>Nos sites</Link>
          </li>
          <li>
            <Link to={'/faq'}>FaQ</Link>
          </li>
        </ul>
        <div className='hidden md:block'>
          {
             hasToken!== null ? <Link to={'/logout'} className='p-2 text-white bg-red-600 rounded-md' onClick={logoutAction}>Déconnexion</Link> : <Link to={'/login'} className='p-2 text-white bg-teal-400 rounded-md' onClick={toggleMobileMenu}>Connexion</Link>
          }
        </div>
        <button
          className='md:hidden'
          onClick={toggleMobileMenu}
        >
          ☰
        </button>
      </nav>

      {isMobileMenuOpen && createPortal(
        <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center z-50'>
          <button
            className='absolute top-4 right-4 text-white'
            onClick={toggleMobileMenu}
          >
            ✕
          </button>
          <ul className='flex flex-col items-center gap-8 text-white'>
            <li>
              <Link to={'/'} onClick={toggleMobileMenu}>Acceuil</Link>
            </li>
            <li>
              <Link to={'/site-exam'} onClick={toggleMobileMenu}>Nos sites</Link>
            </li>
            <li>
              <Link to={'/faq'} onClick={toggleMobileMenu}>FaQ</Link>
            </li>
            <li>
              {
               hasToken!== null ? <Link to={'/logout'} className='p-2 text-white bg-red-600 rounded-md' onClick={logoutAction}>Déconnexion</Link> : <Link to={'/login'} className='p-2 text-white bg-teal-400 rounded-md' onClick={toggleMobileMenu}>Connexion</Link>
              }
            </li>
          </ul>
        </div>,
        document.body
      )}
    </div>
  );
}

export default Navbar;
