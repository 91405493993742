import React, { useState } from 'react';
import { LuArrowRight } from 'react-icons/lu';
import { LuArrowLeft } from 'react-icons/lu';
import { fieldSet, notEmpty } from '../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { push_candidate_info } from '../../app/modules/candidate';
import { toast } from 'react-toastify';
import { nextStep, prevStep } from '../../app/modules/stepper';
import { getCentreDepot, getCentreExamen, getSiteFormation } from '../../api/routes/concours';
function ConcourInfo({ setLoadingState }) {
    const [formData, setFormData] = useState({});
    const step = useSelector((state) => state.stepper.step);
    const totalSteps = useSelector((state) => state.stepper.totalSteps);
    const [centreDepot, setCentreDepot] = useState([])
    const [centreExamen, setCentreExamen] = useState([])
    const [siteFormation, setSiteFormation] = useState([])
    const {finish,...userData} = useSelector((state)=>state.candidate.candidate_state)
    function fetchCentreExamen() {
        getCentreExamen().then(async(res)=>{
            if (res.status ===200) {
                const data = await res.json()
                return setCentreExamen(data)
            }
        })
    }
    function fetchCentreDepot() {
        getCentreDepot().then(async(res)=>{
            if (res.status ===200) {
                const data = await res.json()
                return setCentreDepot(data)
            }
        })
    }
    function fetchSiteFormation() {
        getSiteFormation().then(async(res)=>{
            if (res.status ===200) {
                const data = await res.json()
                return setSiteFormation(data)
            }
        })
    }
    React.useEffect(() => {
        fieldSet('#form_concours', setFormData, {...userData})
        fetchCentreDepot()
        fetchCentreExamen()
        fetchSiteFormation()
        return () => {
            fieldSet('#form_concours', setFormData, {...userData})
            fetchCentreDepot()
            fetchCentreExamen()
            setLoadingState(false)
            fetchSiteFormation()
        };
    }, [])
    function onChange(e) {
        setFormData(prevData => ({
            ...prevData,
            [e.target?.name]: e.target?.value
        }));
    }
    const dispatch = useDispatch()
    function onSubmit(e) {
        try {
            e.preventDefault();
            if (notEmpty(formData)) {
                // Form processing logic
                setLoadingState(true)
                dispatch(push_candidate_info(formData))
                setTimeout(() => {
                    setLoadingState(false)
                    dispatch(nextStep())
                }, 2000);
            } else {
                // Handle empty form error
                toast.error("Veuillew remplir tous les champs...")
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            <h1 className='text-3xl font-bold text-teal-500'>
                Informations liées au concours
            </h1>
            <form action="" method="post" id='form_concours' onSubmit={onSubmit}>
                <div className="grid items-center grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
                    <div className='flex flex-col gap-3 mb-3'>
                        <label htmlFor="centre_exam">Centre d'examen <sup className='text-red-600'>*</sup></label>
                        <select id='centre_exam' value={formData.centre_exam}  name="ca_centre_examen" placeholder='Exp: Lycée Bilingue d Abam' className='p-2 border border-teal-600 rounded-md outline-none focus:outline-teal-600/15 indent-1' onChange={onChange} >
                        <option value="">Selectionner un centre</option>
                        {centreExamen.map((cE,k)=>(
                            <option value={cE.centre_exam_code} key={k}>{cE.centre_exam_label}</option>
                        ))}
                        </select>
                        
                    </div>
                    <div className='flex flex-col gap-3 mb-3'>
                        <label htmlFor="centre_depot">Centre de dépot <sup className='text-red-600'>*</sup></label>
                        <select id='centre_depot' value={formData.centre_depot} name="ca_centre_depot" placeholder='Exp: Lycée Bilingue d Abam' className='p-2 border border-teal-600 rounded-md outline-none focus:outline-teal-600/15 indent-1' onChange={onChange} >
                        <option value="">Selectionner un centre</option>
                        {centreDepot.map((cD,k)=>(
                            <option value={cD.centre_depot_code} key={k}>{cD.centre_depot_label}</option>
                        ))}
                        </select>
                    </div>
                    <div className='flex flex-col gap-3 mb-3'>
                        <label htmlFor="site_formation">Site de formation <sup className='text-red-600'>*</sup></label>
                        <select id='site_formation' name="code_site" value={formData.code_site}  className='p-2 border border-teal-600 rounded-md outline-none focus:outline-teal-600/15 indent-1' onChange={onChange} >
                        <option value=''>Selection votre site de formation</option>
                        {siteFormation.map((sF,k)=>(
                            <option value={sF.code_site} key={k}>{sF.label_site}</option>
                        ))}
                        </select>
                    </div>
                </div>
                <div className="flex justify-between">
                    <button
                        type='button'
                        onClick={() => dispatch(prevStep())}
                        className={`px-4 py-2 flex items-center gap-4 rounded ${step === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-teal-600 text-white hover:bg-teal-700'}`}
                        disabled={step === 1}
                    >
                        <LuArrowLeft />  Précédent
                    </button>
                    <button
                        type='submit'

                        className={`px-4 py-2 flex items-center gap-4 rounded ${step === totalSteps ? 'bg-gray-300 cursor-not-allowed' : 'bg-teal-600 text-white hover:bg-teal-700'}`}
                        disabled={step === totalSteps}
                    >
                        Suivant <LuArrowRight />
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ConcourInfo;
